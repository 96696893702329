import api from "~/utils/api/api";

const reportsBaseUrl = "analytics/reports";
class ReportService {
  fetchFonasaReport = async (date: string, labRut: string) => {
    return api.get(`${reportsBaseUrl}/fonasa`, { params: { date, lab_rut: labRut }, responseType: "blob" });
  };
}

const reportService = new ReportService();

export default reportService;
